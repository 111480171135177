/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Suspense} from "react";
import ReactDOM from "react-dom";

// styles
import 'swiper/swiper-bundle.css';
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import App from "./App";
import Loader from './components/Loader/Loader'
import { HelmetProvider } from 'react-helmet-async'

// others

const WrappedApp = () => (
  <HelmetProvider>
    <Suspense fallback={<Loader/>}>
      <App />
    </Suspense>
  </HelmetProvider>
)

ReactDOM.render(
    <WrappedApp/>
  ,
  document.getElementById("root")
);