import React from 'react'
// reactstrap components
import {
  Button,
  Collapse,
  Container,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap'
import { FooterContent } from '../Footers/FooterBlack'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import LanguagesDropdown from '../LanguagesDropdown/LanguagesDropdown'
import { google_maps_coordinates } from '../../constants'
import { useHistory } from 'react-router'

const StyledNavContainer = styled(Container)`
  @media (min-width: 1280px) {
    max-width: 100%;
  }
`

const StyledNavItem = styled(NavItem)`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`

const StyledContactUsBtn = styled(Button)`
  > i {
    font-size: 16px;
    top: 3px !important;
    margin-right: 0.5rem;
  }
`

const StyledP = styled.p`
  text-transform: uppercase;
`

function ScrollTransparentNavbar() {
  const history = useHistory()
  const { t } = useTranslation()
  const [collapseOpen, setCollapseOpen] = React.useState(false)

  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499 ? '' : ' navbar-transparent'
  )
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499 ? 'info' : 'neutral'
  )
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 499 || document.body.scrollTop > 499) {
        setNavbarColor('')
        setBuyButtonColor('info')
      } else if (document.documentElement.scrollTop < 500 || document.body.scrollTop < 500) {
        setNavbarColor(' navbar-transparent')
        setBuyButtonColor('neutral')
      }
    }
    window.addEventListener('scroll', updateNavbarColor)
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor)
    }
  })

  const handleRouteClick = (path) => {
    history.push(path)
    // setCurSection(path)
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open')
            setCollapseOpen(false)
          }}
        />
      ) : null}
      <Navbar className={'fixed-top' + navbarColor} color="white" expand="lg">
        <StyledNavContainer>
          <div className="navbar-translate">
            <NavbarBrand onClick={() => handleRouteClick('/')} id="navbar-brand">
              <img
                src="/mstile-150x150.png"
                alt="logo"
                style={{
                  width: '50px',
                  height: '50px',
                  marginTop: '5px',
                }}
              />
              {t('common:general.appTitle')}
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open')
                setCollapseOpen(!collapseOpen)
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
              aria-label="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  onClick={() => handleRouteClick('/portfolio')}
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i aria-hidden={true} className="now-ui-icons design_image"></i>
                  <p>Portfolio</p>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  onClick={() => handleRouteClick('/video')}
                  id="navbarDropdownMenuLink1"
                  nav
                >
                  <i aria-hidden={true} className="now-ui-icons media-1_button-play"></i>
                  <p>Video</p>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  color="default"
                  data-toggle="dropdown"
                  onClick={() => handleRouteClick('/platforms')}
                  id="navbarDropdownMenuLink2"
                  nav
                >
                  <i aria-hidden={true} className="now-ui-icons shopping_shop"></i>
                  <p>Platforms</p>
                </DropdownToggle>
              </UncontrolledDropdown>
              <LanguagesDropdown />
              <NavItem>
                <StyledContactUsBtn
                  className="nav-link btn-default"
                  color={buyButtonColor}
                  onClick={() => handleRouteClick('/contact-us')}
                >
                  <i aria-hidden={true} className={'now-ui-icons ui-1_email-85'}></i>
                  <StyledP>{t('common:actions.contact-us')}</StyledP>
                </StyledContactUsBtn>
              </NavItem>
              <StyledNavItem>
                <FooterContent {...google_maps_coordinates} />
              </StyledNavItem>
            </Nav>
          </Collapse>
        </StyledNavContainer>
      </Navbar>
    </>
  )
}

export default ScrollTransparentNavbar
