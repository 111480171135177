import {useEffect, useState} from "react";

const getAppWidth = () => window.innerWidth;

export function useWindowSize() {
    const [windowWidth, setWindowWidth] = useState(getAppWidth);

    useEffect(() => {
        const onResize = () => {
            setWindowWidth(getAppWidth);
        }

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    return {width: windowWidth, imgKitResponsivePath: windowWidth > 768 ? 'desktop' : 'mobile'};
}