import React from 'react'
import {Button, Col, Container, Row} from 'reactstrap'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import GenericLayout from './GenericLayout'

const StyledContainer = styled(Container)`
  top: 25%
`

const StyledContent = styled(Row)`
  justify-content: center;
`

const StyledCentered = styled(Col)`
  text-align: center;
`

function ErrorLayout({children}) {

    const {t} = useTranslation()

    return (
        <GenericLayout>
            <StyledContainer>
                <Row>
                    <StyledCentered md='12'>
                        {children}
                    </StyledCentered>
                </Row>
                <StyledContent>
                    <StyledCentered xs='8' md='6'>
                        <Button
                            block
                            className='btn-round'
                            color='info'
                            href={process.env.PUBLIC_URL + '/'}
                            size='lg'
                        >
                            {t('common:actions.back-to-home')}
                        </Button>
                    </StyledCentered>
                </StyledContent>
            </StyledContainer>
        </GenericLayout>
    )
}

export default ErrorLayout