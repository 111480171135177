import styled from 'styled-components'

const StyledWrapper = styled.div`
  height: 100vh;
`

function GenericLayout({ children }) {
  return (
    <StyledWrapper>
      <div
        className="contactus-1 section-image"
        style={{
          height: '100vh',
          backgroundImage: 'url(' + require(`assets/img/presentation-page/Suites-RGB-portrait.webp`).default + ')',
          backgroundPosition: 'center center',
        }}
      >
        {children}
      </div>
    </StyledWrapper>
  )
}

export default GenericLayout
