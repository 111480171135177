import styled from 'styled-components'
import GenericLayout from '../../views/auth/GenericLayout'
import { Container } from 'reactstrap'

const CenteredContainer = styled(Container)`
  top: 45%;
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  > i {
    font-size: 7rem;
  }
`

function Loader() {

  return (
    <GenericLayout>
      <CenteredContainer>
        <StyledWrapper className='description'>
          <i className={'now-ui-icons loader_refresh spin'}></i>
        </StyledWrapper>
      </CenteredContainer>
    </GenericLayout>
  )
}

export default Loader