import { useLang, getFlagDetails } from '../../context/LangContextProvider'
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import ReactCountryFlag from 'react-country-flag'
import DropdownMenu from 'reactstrap/es/DropdownMenu'
import DropdownItem from 'reactstrap/es/DropdownItem'
import styled from 'styled-components'

const StyledDropdownMenu = styled(DropdownMenu)`
  overflow-y: hidden !important;
  background-color: #887958 !important;

  > button:hover {
    color: #fff;
  }
`

const commonStyles = {
  width: '20px',
  height: '15px',
  borderRadius: '10%',
  marginRight: '0.5rem',
  verticalAlign: 'middle',
}

const dropdownConfig = [
  {
    lang: 'el',
    countryCode: 'GR',
    title: 'Greek',
    style: commonStyles,
  },
  {
    lang: 'en',
    countryCode: 'GB',
    title: 'English',
    style: commonStyles,
  },
  {
    lang: 'de',
    countryCode: 'DE',
    title: 'German',
    style: commonStyles,
  },
  {
    lang: 'fr',
    countryCode: 'FR',
    title: 'French',
    style: commonStyles,
  },
]

function LanguagesDropdown() {
  const { currentLang, setLang } = useLang()

  function changeLang(lang) {
    setLang(lang)
  }

  const { countryCode, title } = getFlagDetails()[currentLang || 'en']

  return (
    <>
      <UncontrolledDropdown nav>
        <DropdownToggle
          color="default"
          data-toggle="dropdown"
          id="navbarDropdownMenuLink1"
          nav
          onClick={(e) => e.preventDefault()}
        >
          <ReactCountryFlag countryCode={countryCode} svg style={commonStyles} title={title} />
          {title}
        </DropdownToggle>
        <StyledDropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
          {dropdownConfig.map(({ lang, title, ...rest }) => (
            <DropdownItem key={lang} onClick={() => changeLang(lang)}>
              <ReactCountryFlag svg title={title} {...rest} />
              {title}
            </DropdownItem>
          ))}
        </StyledDropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default LanguagesDropdown
