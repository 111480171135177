import React, { lazy, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Presentation from './views/Presentation'
import ErrorBoundary from './views/sections-sections/ErrorBoundary'
import i18n from './i18n'
import { LangContext } from './context/LangContextProvider'

import Cookies from 'universal-cookie'
import { routes } from './constants'

const Page404 = lazy(() => import('./views/auth/Page404'))

const cookies = new Cookies()

function App() {

    const [currentLang, setLang] = React.useState(cookies.get('TRANSLATE_LANG_KEY'))

    useEffect(() => {
        i18n.changeLanguage(currentLang)
    }, [currentLang])

    return (
      <ErrorBoundary>
          <LangContext.Provider value={{ currentLang, setLang }}>
              <BrowserRouter>
                  <Switch>
                      {routes.map(({ id: key, link: path }) => (
                        <Route
                          exact
                          key={key}
                          path={path}
                          render={(props) => <Presentation {...props} />}
                        />
                      ))}
                      <Route component={Page404} />
                  </Switch>
              </BrowserRouter>
          </LangContext.Provider>
      </ErrorBoundary>
    )
}

export default App