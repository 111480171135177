import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import Carousel from '../../components/CustomCarousel/Carousel'

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledTitle = styled.h2`
  color: #ffffff;
  margin-bottom: 16px;
`

function Portfolio() {
  return (
    <div className="page-header page-header-medium" id={'portfolio'}>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <StyledTitle className="text-center title">Portfolio</StyledTitle>
        </Col>
        <Col className="ml-auto mr-auto" md="12" lg="8">
          <StyledContainer>
            <Carousel />
          </StyledContainer>
        </Col>
      </Row>
    </div>
  )
}

export default Portfolio
