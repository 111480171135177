import React from 'react'
import YouTube from 'react-youtube'

import "./VideoPlayer.css"

export function VideoPlayer({youtubeId}) {

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    return (
        <YouTube
            videoId={youtubeId}
            className={'youtubeContainer'}
            opts={{
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    controls: 1, playsinline: !isSafari
                }
            }}
        />
    )

}