import { scroller } from 'react-scroll'

/**
 * scrolling function
 * @param id - input id to navigate to
 * @param config - providing custom config
 */
export function navigateTo(id, config) {
  scroller.scrollTo(id, config || {
    duration: 500,
    offset: -10,
    delay: 500,
    smooth: true
  })
}