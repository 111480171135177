import React, { createContext } from "react";
import Page500 from '../auth/Page500'


export const ErrorContext = createContext({
    setError: (error) => {}
})

class ErrorBoundary extends React.Component {

    state = {
        hasError: false,
        errorMessage: ""
    }

    setError(error){
        this.setState({
            hasError: error,
            errorMessage: error
        })
    }

    static getDerivedStateFromError(_) {
        //update state so the next render will show the fallback ui
        return {hasError: true, errorMessage: _.message}
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }


    // {/*/* maybe use some mechanism for re-routing outside of boundary */*/}
    render() {
        return (
            <ErrorContext.Provider value={{
                setError: this.setError.bind(this)
            }}>
                {
                    this.state.hasError && (
                        <Page500 errorMessage={this.state.errorMessage}/>
                    )
                }

                {!this.state.hasError && this.props.children}
            </ErrorContext.Provider>
        );
    };
};

export default ErrorBoundary;