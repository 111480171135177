/*eslint-disable*/
import React from "react";

// reactstrap components
import {Container} from "reactstrap";
import styled from "styled-components/macro"
import {facebook_url, instagram_url, youtube_url} from "../../constants";
import {useTranslation} from "react-i18next";

const socialMediaConfig = [
    {
        url: facebook_url,
        key: "facebook",
        component: () => <i className="fab fa-lg fa-facebook-square"/>
    },
    {
        url: instagram_url,
        key: "instagram",
        component: () => <i className="fab fa-lg fa-instagram"/>
    },
    {
        url: youtube_url,
        key: "youtube",
        component: () => <i className="fab fa-lg fa-youtube"/>
    }
]

const StyledList = styled.ul`
  padding-left: 0;
  display: flex;
  vertical-align: middle;

  > li {
    
    ::marker {
      content: '';
    }
    
    @media (max-width: 991px) {
      padding: 15px;
      text-align: center;
    }
    
    > a > span {
      vertical-align: middle;
    }
  }
`

const FooterDesktopContent = styled.footer`
  @media (max-width: 1000px) {
    display: none;
  }
`

const StyledI = styled.i`
  font-size: 1.33333em;
  margin-top: -3px;
  padding: 0 8px;
  vertical-align: middle;

  @media (max-width: 991px) {
    padding: 4px 0;
    margin-top: 0;
    + span {
      display: none;
    }
  }
  
`


export const FooterContent = ({long, lat}) => {
    const {t} = useTranslation()
    return (
        <StyledList>
            {socialMediaConfig.map(({component, key, url}) => {
                return (
                    <li key={key} style={{display: "inline-block"}}>
                        <a
                            href={url}
                            target={"_blank"}
                            aria-label={key}
                            rel="noreferrer"
                        >
                            <span>{component()}</span>
                        </a>
                    </li>
                )
            })}
            <li>
                <a
                    href={`https://maps.google.com?q=${long},${lat}`}
                    target={"_blank"}
                    aria-label={'google-maps'}
                    rel="noreferrer"
                >
                <span>
                    <StyledI className="now-ui-icons location_pin" aria-hidden="true"/>
                        <span>{t("common:actions.find-us")}</span>
                    </span>
                </a>
            </li>
        </StyledList>
    )

}


// core components

function FooterBlack(props) {
    const {t} = useTranslation()

    return (
        <>
            <FooterDesktopContent className="footer" data-background-color="black">
                <Container>
                    <nav>
                        <FooterContent {...props}/>
                    </nav>
                    <div className="copyright" id="copyright">
                        © {new Date().getFullYear()}, {" "}
                        <a
                            href={process.env.PUBLIC_URL + '/'}
                        >
                            {t("common:general.appTitle")}
                        </a>
                    </div>
                </Container>
            </FooterDesktopContent>
        </>
    );
}

export default FooterBlack;