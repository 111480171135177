/*eslint-disable*/
import React from 'react'
import styled from 'styled-components'
import {useWindowSize} from '../../hooks/useWindowSize'
import {useTranslation} from 'react-i18next'

// reactstrap components

const StyledHeroImg = styled.div`

  width: 100%;
  top: 24%;
  height: 300px;
  background-size: 820px !important;
  background: white no-repeat center;

  @media (max-width: 991px) {
    top: 15%;
    background-size: 380px !important;
    width: 370px;
    height: 370px;
  }

`

const StyledContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const StyledDescription = styled.h3`
  padding-right: 15px;
  padding-left: 15px;
`

// core components

function PresentationHeader() {

    const {t} = useTranslation()
    const {width} = useWindowSize()

    return (
        <div className='page-header' id={'presentation'}>
            <div>
                <StyledContainer>
                    <StyledHeroImg
                        className='page-header-image'
                        style={{
                            backgroundImage:
                                'url(' +
                                require(`assets/img/presentation-page/Suites-RGB${width > 991 ? '' : '-sm'}.webp`)
                                    .default +
                                ')'
                        }}
                    />
                </StyledContainer>

            </div>
            <StyledDescription className='h3-description'>
                {t('common:general.hero-text')}
            </StyledDescription>
        </div>
    )
}

export default PresentationHeader