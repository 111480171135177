export const google_maps_coordinates = {
  long: '35.08151',
  lat: '24.74110',
}

export const client_details = {
  email: 'suitesdeluxek@gmail.com',
  telephone: '6980583652',
}

export const instagram_url = 'https://www.instagram.com/suites_de_luxe_katerina/'
export const youtube_id = 'oDkpISx2YLo'
export const youtube_url = `https://www.youtube.com/watch?v=${youtube_id}`
export const facebook_url =
  'https://www.facebook.com/Suites-De-Luxe-Katerina-111668328244932/?notif_id=1655564403458561'
export const airbnb_url =
  'https://www.airbnb.gr/rooms/650032101252947186?_set_bev_on_new_domain=1655328633_NTIyNDBjOWVkMjU1&source_impression_id=p3_1655565029_TP90b7BsyoW8yTUx'
export const booking_url = 'https://www.booking.com/hotel/gr/suites-de-luxe-katerina.el.html'

export const routes = [
  {
    link: '/',
    name: 'header.presentation',
    id: 'presentation',
    title: 'Suites De Luxe Katerina',
    meta: 'Luxurious Cretan experience and relaxation at Heraklion Crete Kokkinos Pyrgos literally just steps away from a beautiful sandy beach',
  },
  {
    link: '/portfolio',
    name: 'header.portfolio',
    id: 'portfolio',
    title: 'Portfolio',
    meta: 'Cretan paradise Suite visuals and impressions! Be sure to see them all!',
  },
  {
    link: '/video',
    name: 'header.video',
    id: 'video',
    title: 'Video',
    meta: 'Before your visit make sure you check our video that will guide you through the premises and become more excited for your vacation!',
  },
  {
    link: '/platforms',
    name: 'header.platforms',
    id: 'platforms',
    title: 'Platforms',
    meta: 'Suites De Luxe Katerina is available on the famous platforms of Airbnb and Booking for your convenience and personal preference',
  },
  {
    link: '/contact-us',
    name: 'header.contact-us',
    id: 'contact-us',
    title: 'Contact Us',
    meta: 'Contact us by either email or ring us using the details provided on the section'
  },
]
