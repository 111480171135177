import React from 'react'
// core components
import Platforms from './sections-sections/Platforms'
import ContactUs from './sections-sections/ContactUs'

// sections for this page
import Video from './sections-sections/Video.js'
import {client_details, google_maps_coordinates, routes} from '../constants'
import SectionContextProvider from '../context/SectionContextProvider'
import {Helmet} from 'react-helmet-async'
import {useHistory} from 'react-router'
import PresentationHeader from "./sections-sections/PresentationHeader";
import Portfolio from "./sections-sections/Portfolio";
import ScrollTransparentNavbar from "../components/Navbars/ScrollTransparentNavbar";

const FooterBlack = React.lazy(()=> import('components/Footers/FooterBlack.js'))

function getHelmetTitle(link) {

  const idx = routes.findIndex(it => it.link === link)
  return idx >= 0 ? {title: routes[idx].title,meta: routes[idx].meta, link} : ''
}


function Presentation() {
  React.useEffect(() => {
    document.body.classList.add('presentation-page')
    document.body.classList.add('sidebar-collapse')
    document.documentElement.classList.remove('nav-open')

    return function cleanup() {
      document.body.classList.remove('presentation-page')
      document.body.classList.remove('sidebar-collapse')
    }
  })

  const { location: { pathname } } = useHistory()

  const helmetObj = getHelmetTitle(pathname)

  return (
    <SectionContextProvider>
      {
        helmetObj && (
          <Helmet>
            <title>{helmetObj.title}</title>
            <meta name={"description"} content={helmetObj.meta}/>
            <link rel='canonical' href={helmetObj.link} />
          </Helmet>
        )
      }
      <ScrollTransparentNavbar />
      <div className='wrapper'>
        <PresentationHeader />
        <Portfolio />
        <Video />
        <Platforms />
        <ContactUs {...client_details} />
        <FooterBlack {...google_maps_coordinates}/>
      </div>
    </SectionContextProvider>
  )
}

export default Presentation