import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";
import {youtube_id} from '../../constants'
import {useTranslation} from "react-i18next";
import styled from "styled-components"

const StyledSection = styled.div`
  padding-bottom: 70px;
`

const StyledTitle = styled.h2`
  color: #FFFFFF;
`


const StyledSub = styled.small`
  color: rgba(255, 255, 255, 0.8);
`

// core components

function Video() {

    const {t} = useTranslation()

    return (
        <StyledSection className="page-header page-header-medium section section-pricing" id="video">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        <StyledTitle className="text-center title">
                            {t("common:actions.take-a-peek-title")}
                            <br></br>
                            <StyledSub className="description">
                                {t("common:actions.take-a-peek-subtitle")}
                            </StyledSub>
                        </StyledTitle>
                    </Col>
                    <Col className="ml-auto mr-auto" md="12">
                        <VideoPlayer youtubeId={youtube_id}/>
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    );
}

export default Video;