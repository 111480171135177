import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { routes } from '../constants'
import * as scrollUtils from '../utils/scrollUtils'

const SectionContextProvider = ({ children }) => {

  //These are Section related values
  const { location: { pathname } } = useHistory()

  const [curSection, setCurSection] = useState(undefined)

  useEffect(() => {

    setCurSection(pathname)

  }, [pathname])

  useEffect(() => {
    let sectionFound = routes.find(i => i.link === pathname)
    if (sectionFound) {
      scrollUtils.navigateTo(sectionFound.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curSection])
  return (
    <>
      {children}
    </>
  )

}


export default SectionContextProvider