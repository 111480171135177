import React from 'react'
import {Button, Card, CardFooter, Col, Container, Row} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import {airbnb_url, booking_url} from '../../constants'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  margin-bottom: 1.7rem;
`

const StyledTitle = styled.h2`
  color: #FFFFFF;
`

const StyledSub = styled.small`
  color: rgba(255, 255, 255, 0.8);
`

function Platforms() {

  const {t} = useTranslation()

  function onClickHandler(path) {
    window.open(path, '_blank', 'noreferrer')
  }

  return (
      <div className='page-header page-header-medium section section-cards' id={'platforms'}>
        <Container>
          <StyledRow>
            <Col className="ml-auto mr-auto" md="6" sm='12'>
              <StyledTitle className="text-center title">
                {t('common:platform-section.title')}
                <br></br>
                <StyledSub className="description">
                  {t('common:platform-section.subtitle')}
                </StyledSub>
              </StyledTitle>
              <h5 className='description'>
                {t('common:platform-section.body')}
              </h5>
            </Col>
          </StyledRow>
          <Row>
            <Col className='text-center ml-auto mr-auto' md='4'>
              <Card className='card-login card-plain'>
                <CardFooter className='text-center'>
                  <Button
                      block
                      className='btn-round'
                      color='info'
                      onClick={() => onClickHandler(booking_url)}
                      size='lg'
                  >
                    Booking
                  </Button>
                </CardFooter> </Card>
            </Col>
            <Col className='text-center ml-auto mr-auto' md='4'>
              <Card className='card-login card-plain'>
                <CardFooter className='text-center'>
                  <Button
                      block
                      className='btn-round'
                      color='info'
                      onClick={() => onClickHandler(airbnb_url)}
                      size='lg'
                  >
                    Airbnb
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default Platforms