import { createContext, useContext, useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'

export const getFlagDetails = () => ({
  el: {
    countryCode: 'GR',
    title: 'Greek',
  },
  en: {
    countryCode: 'GB',
    title: 'English',
  },
  de: {
    countryCode: 'DE',
    title: 'German',
  },
  fr: {
    countryCode: 'FR',
    title: 'French',
  },
})

const defaultStateValue = {
  currentLang: 'el',
  setLang: (_) => console.warn('no LangContextProvider'),
}

//Lang context export no need to use this atomically
export const LangContext = createContext({
  ...defaultStateValue,
})

export const useLang = () => useContext(LangContext)

const LangContextProvider = ({ children }) => {
  const [currentLang, setLang] = useState(i18n.language)

  useEffect(() => {
    i18n.changeLanguage(currentLang)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang])

  return (
    <I18nextProvider i18n={i18n}>
      <LangContext.Provider value={{ currentLang, setLang }}>{children}</LangContext.Provider>
    </I18nextProvider>
  )
}

export default LangContextProvider
