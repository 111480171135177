// reactstrap components
import { Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledContainer = styled.p`
  font-weight: 400;
`

function ContactUs({ email, telephone }) {
  const { t } = useTranslation()

  return (
    <div className="cd-section" id="contact-us">
      <div
        className="contactus-1 section-image"
        style={{
          backgroundImage:
            'url(' +
            require('assets/img/contact-us.webp').default +
            '), linear-gradient(rgba(96,83,53,0.5),rgba(96,83,53,0.5))',
          backgroundBlendMode: 'overlay',
        }}
      >
        <Container>
          <Row>
            <Col md="5">
              <h2 className="title">{t('common:contact-section.title')}</h2>
              <h4 className="description">
                {t('common:contact-section.subtitle1')}
                <br />
                {t('common:contact-section.subtitle2')}
              </h4>

              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons ui-1_email-85"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">{t('common:contact-section.email')}</h4>
                  <StyledContainer className="description">
                    {email}
                    <br></br>
                    <a href={`mailto:${email}`} target={'_blank'} rel="noreferrer">
                      {t('common:contact-section.email-cta')}
                    </a>
                  </StyledContainer>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-info">
                  <i className="now-ui-icons tech_mobile"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">{t('common:contact-section.phone')}</h4>
                  <StyledContainer className="description">
                    {t('common:contact-section.host')}
                    <br></br>
                    <a href={`tel:${telephone}`} rel="noreferrer">
                      {t('common:contact-section.phone-cta')} {telephone}
                    </a>
                    <br></br>
                    {t('common:contact-section.phone-hours')}
                  </StyledContainer>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ContactUs
