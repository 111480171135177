import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

/* i18n language detector specifics */
const languageDetectorOptions = {
    // order and from where user language should be detected
    order: ["cookie"],

    // keys or params to lookup language from
    lookupCookie: "TRANSLATE_LANG_KEY",
    lookupLocalStorage: "TRANSLATE_LANG_KEY",

    // cache user language on
    caches: ["cookie"],
};

i18n
    // i18next-http-backend
    // loads translations from your server (or locales at public folder)
    .use(Backend)
    //language detector
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: languageDetectorOptions,
        debug: process.env.NODE_ENV === "development",
        // detection: languageDetectorOptions,
        fallbackLng: "en",
        defaultNS: "common",
        react: {
            useSuspense: true
        }
    })

export default i18n;