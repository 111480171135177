// Import Swiper React components
import { Keyboard, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import './Carousel.css'

const qualityPostFix = ',q-70'

function Carousel() {
  return (
    <Swiper
      style={{
        width: '100%',
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }}
      loop={true}
      lazy={true}
      keyboard={{
        enabled: true,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Keyboard, Lazy, Pagination, Navigation]}
      className="mySwiper"
    >
      {Array.from({ length: 10 }, (x, i) => i + 1).map((i) => {
        const imgPath = `swiper/fullsize/image-${i}.jpg`
        const imgSrc = `${process.env.REACT_APP_IMAGEKIT_BASE_URL}/tr:w-800/${imgPath}`

        const imgSrcSetArrConf = [
          `${process.env.REACT_APP_IMAGEKIT_BASE_URL}/tr:w-600,q-50/${imgPath} 600w`,
          `${process.env.REACT_APP_IMAGEKIT_BASE_URL}/tr:w-800${qualityPostFix}/${imgPath} 800w`,
          `${process.env.REACT_APP_IMAGEKIT_BASE_URL}/tr:w-1200${qualityPostFix}/${imgPath} 1200w`,
        ]

        const imgSrcSet = imgSrcSetArrConf.join(',')

        return (
          <SwiperSlide key={`image-${i}`}>
            <img srcSet={imgSrcSet} src={imgSrc} sizes="100vw" alt={`carousel-${i}`} loading="lazy" />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default Carousel
