import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ErrorLayout from './ErrorLayout'


const StyledSub = styled.h4`
  margin-bottom: 50px;
`

function Page500({ errorMessage }) {

  const { t } = useTranslation()
  return (
    <ErrorLayout>
      <h2 className='title'>ERROR 500 Internal Server Error</h2>
      <StyledSub className='description'>
        {t('common:error-500.subtitle')}
      </StyledSub>
      {
        process.env.NODE_ENV === 'development' && (
          <StyledSub className='description'>
            {errorMessage}
          </StyledSub>
        )
      }
    </ErrorLayout>
  )
}

export default Page500